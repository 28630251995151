// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import PageWrapper from '../components/PageWrapper';
import PageHelmet from '../plugins/helmet/components/PageHelmet';
import RichText from '../plugins/prismic/components/RichText';
import Link from '../plugins/i18n/components/Link';
import NodeImage from '../plugins/prismic/components/NodeImage';
import type { Query } from '../schema.flow';

const styles = (theme: Theme) => ({
  root: {},
  wrapper: {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  imageContainer: {
    paddingRight: 20,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingBottom: 20,
    },
  },
  image: {
    width: 150,
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  link: {},
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  data: Query,
};

const NotFoundPage = ({ classes, data: query }: Props) => (
  <PageWrapper
    limitWidth
    guttersH
    guttersV
    classes={{ root: classes.root, wrapper: classes.wrapper }}
  >
    <PageHelmet data={query.prismic404Page && query.prismic404Page.data} />
    <div className={classes.imageContainer}>
      <NodeImage
        data={query.prismic404Page?.data?.image}
        className={classes.image}
      />
    </div>
    <div className={classes.container}>
      {query.prismic404Page?.data?.title?.text ? (
        <Typography variant="h4" gutterBottom>
          {query.prismic404Page?.data?.title?.text}
        </Typography>
      ) : null}
      {RichText.hasValue(query.prismic404Page?.data?.description) ? (
        <Typography component="div">
          <RichText {...query.prismic404Page?.data?.description} />
        </Typography>
      ) : null}
      {query.prismic404Page?.data?.button_home?.text ? (
        <Link className={classes.link} to="/">
          <Button variant="outlined" color="primary">
            {query.prismic404Page?.data?.button_home?.text || ''}
          </Button>
        </Link>
      ) : null}
    </div>
  </PageWrapper>
);

export default withStyles(styles)(NotFoundPage);

export const query = graphql`
  query NotFoundPageQuery($prismicLocaleId: String) {
    prismic404Page(lang: { eq: $prismicLocaleId }) {
      id
      lang
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        title {
          text
        }
        description {
          html
        }
        button_home {
          text
        }
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 150) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
